<template>
  <div>
    <gl-title :title="$route.meta.title"></gl-title>
    <div style="margin: 18px;">
      <el-button v-check="'107021'" type="primary" size="mini" @click="mClickAction('mUpdateVisible')"> 新增 </el-button>
    </div>
    <gl-page-header>
      <el-form :model="mFormData" ref="listForm" :inline="true" size="mini">
        <el-form-item prop="phone" label="轮播图">
          <gl-option v-model="mFormData.position" :list="bannerPositionList"></gl-option>
        </el-form-item>
        <el-form-item>
          <el-button  type="primary" icon="el-icon-search" @click="mReload"> 搜索 </el-button>
          <el-button icon="el-icon-refresh" @click="mResetForm('listForm')"> 重置 </el-button>
          <!-- <el-button icon="el-icon-refresh" @click="resetForm('listForm')"> 重置 </el-button> -->
        </el-form-item>
      </el-form>
    </gl-page-header>
    <gl-card v-loading="mLoading">
      <el-table :data="mTableData" style="width: 100%">
        <el-table-column type="index" :index="mIndexMethod" label="序号" align="center" min-width="30"></el-table-column>
        <el-table-column prop="bannerImg" label="轮播图">
          <template slot-scope="scope">
            <el-image
              style="width: 60px; height: 60px"
              :src="scope.row.bannerImg"
              fit="cover"
              :preview-src-list="[scope.row.bannerImg]"
            />
          </template>
        </el-table-column>
        <el-table-column prop="bannerType" label="跳转类型" show-overflow-tooltip>
          <template slot-scope="{row}">
            <span v-if="row.bannerType == 1"> 商品 </span>
            <span v-else> 活动 </span>
          </template>
        </el-table-column>
        <el-table-column prop="status" label="状态">
          <template slot-scope="{row}">
            <span v-if="row.status == 1" style="color: #67c23a">显示</span>
            <span v-if="row.status == 0">隐藏</span>
          </template>
        </el-table-column>
        <el-table-column prop="position" label="轮播位置">
          <template slot-scope="{row}">
            <span v-if="row.position == 1">首页轮播图</span>
            <span v-else>轮播图</span>
          </template>
        </el-table-column>
        <el-table-column prop="bannerUrl" label="URL" show-overflow-tooltip></el-table-column>
        <el-table-column prop="sort" label="排序"></el-table-column>
        <el-table-column label="操作" fixed="right">
          <template slot-scope="scope">
            <el-button v-check="'107022'" type="text" @click="mClickAction('mUpdateVisible', scope.row.bannerId)"> 编辑 </el-button>
            <el-button type="text" @click="mClickAction('mPreviewVisible', scope.row.bannerId)"> 查看 </el-button>
          </template>
        </el-table-column>
      </el-table>
      <gl-pagination
        :total="mTotal"
        :current-page="mPageNum"
        @currentChange="mHandleCurrentChange"
        @sizeChange="mHandleSizeChange">
      </gl-pagination>
    </gl-card>
    <update :visible.sync="mUpdateVisible" :itemId="mItemId" @reload="mReload"/>
    <preview :visible.sync="mPreviewVisible" :itemId="mItemId"/>
  </div>
</template>

<script>
import {bannerList} from '@/api/backendall/systemSetup'
import ListMixin from '@/mixins/list.mixin.js'
import update from './update.vue'
import preview from './preview.vue'
export default {
  components: {update,preview},
  mixins: [ListMixin],
  data(){
    return{
      bannerPositionList: [
        {name: '首页轮播图', value: '1'},
      ],
    }
  },
  mounted(){
    this.mGetListFun = bannerList
    // this.mFormData.position = this.bannerPositionList[0].value
    this.mGetList()
  },
  methods: {
    resetForm(){
      this.mFormData.position = this.bannerPositionList[0].value
    }
  },
}
</script>

<style>

</style>