<template>
  <el-dialog 
    title="详情"
    :visible="visible"
    :close-on-click-modal="true"
    :before-close="handleClose"
    width="600px"
  >
    <el-form :model="formData" ref="form" label-width="120px" size="mini" v-loading="loading">
      <el-form-item prop="bannerImg" label="轮播图">
        <div>
          <template>
            <el-image
              style="width: 100px; height: 100px;"
              :src="formData.bannerImg"
              fit="cover"
              :preview-src-list="[formData.bannerImg]"
            />
          </template>
        </div>
      </el-form-item>
      <el-form-item prop="bannerUrl" label="跳转地址">
        <div> {{formData.bannerUrl}} </div>
      </el-form-item>
      <el-form-item prop="bannerType" label="类型">
        <div> {{formData.bannerType | filterType}} </div>
      </el-form-item>
      <el-form-item prop="position" label="banner位置">
        <div> {{formData.position == 1 ? '首页轮播图':''}} </div>
      </el-form-item>
      <el-form-item prop="status" label="状态">
        <div> {{formData.status | filterStatus}} </div>
      </el-form-item>
      <el-form-item prop="sort" label="排序">
        <div> {{formData.sort}} </div>
      </el-form-item>
    </el-form> 
    <gl-flex>
      <el-button @click="handleClose" >关闭</el-button>
    </gl-flex>
  </el-dialog>
</template>

<script>
import {getBannerById} from '@/api/backendall/systemSetup'
export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    itemId: {
      type: String,
      default: '',
    },
  },
  data(){
    return{
      formData: {},
      loading: false,
    }
  },
  watch: {
    visible: {
      handler: function(newVal){
        newVal && this.itemId && this.getBanner()
      },
    }
  },
  methods: {
    async getBanner(){
      this.loading = true
      try{
        const res = await getBannerById(this.itemId)
        this.loading = false
        res.data.bannerType = String(res.data.bannerType)
        res.data.position = String(res.data.position)
        res.data.status = String(res.data.status)
        this.oldList = [res.data.bannerImg]
        this.formData = res.data
      }catch(e){
        this.loading = false
      }
    },
    handleClose(){
      this.$emit('update:visible', false)
      this.formData = {}
      this.$refs.form.resetFields()
    },
  },
  filters: {
    filterType(val){
      let text = ''
      if(val == 1){
        text = '商品'
      }else if(val == 2){
        text = '活动'
      }else {
        text = ''
      }
      return text
    },
    filterStatus(val){
      let text = ''
      if(val == 0){
        text = '隐藏'
      }else if(val == 1){
        text = '显示'
      }
      return text
    }
  },
}
</script>

<style lang="scss" scoped>

</style>