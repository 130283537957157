<template>
  <el-dialog
    :title="itemId ? '修改' : '新增'"
    :visible="visible"
    :close-on-click-modal="true"
    :before-close="handleClose"
    width="600px"
  >
    <gl-wrap right="30px">
      <el-form
        :model="formData"
        ref="form"
        label-width="120px"
        size="mini"
        v-loading="loading"
      >
        <el-form-item prop="bannerImg" label="轮播图" verify>
          <gl-upload-images
            v-model="formData.bannerImg"
            :limit="1"
            :oldList="oldList"
          ></gl-upload-images>
        </el-form-item>
        <el-form-item prop="bannerUrl" label="跳转地址" verify>
          <el-input
            v-model="formData.bannerUrl"
            placeholder="请输入跳转地址"
          ></el-input>
        </el-form-item>
        <el-form-item prop="bannerType" label="类型" verify>
          <gl-option v-model="formData.bannerType" :list="typeList"></gl-option>
        </el-form-item>
        <el-form-item prop="position" label="banner位置" verify>
          <gl-option
            v-model="formData.position"
            :list="bannerPositionList"
          ></gl-option>
        </el-form-item>
        <el-form-item prop="status" label="状态" verify>
          <gl-option v-model="formData.status" :list="statusList"></gl-option>
        </el-form-item>
        <el-form-item prop="sort" label="排序" verify>
          <el-input
            v-model.number="formData.sort"
            placeholder="请输入排序号"
          ></el-input>
        </el-form-item>
      </el-form>
    </gl-wrap>
    <gl-flex>
      <el-button type="primary" @click="onSubmit">确认</el-button>
      <el-button @click="handleClose">关闭</el-button>
    </gl-flex>
  </el-dialog>
</template>

<script>
import {
  getBannerById,
  addBanner,
  updateBanner,
} from '@/api/backendall/systemSetup'

export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    itemId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      oldList: [],
      formData: {},
      loading: false,
      typeList: [
        { name: '商品', value: '1' },
        { name: '活动', value: '2' },
      ],
      bannerPositionList: [{ name: '首页轮播图', value: '1' }],
      statusList: [
        { name: '隐藏', value: '0' },
        { name: '显示', value: '1' },
      ],
    }
  },
  watch: {
    visible: {
      handler: function (newVal) {
        newVal && this.itemId && this.getBanner()
      },
    },
  },
  methods: {
    onSubmit() {
      this.$refs.form.validate(async (valid) => {
        if (!valid) return false
        this.loading = true
        const params = {
          bannerImg: this.formData.bannerImg,
          bannerUrl: this.formData.bannerUrl,
          bannerType: Number(this.formData.bannerType),
          position: Number(this.formData.position),
          status: Number(this.formData.status),
          sort: Number(this.formData.sort),
        }
        try {
          let res = null
          if (this.itemId) {
            params.bannerId = this.formData.bannerId
            res = await updateBanner(params)
          } else {
            res = await addBanner(params)
          }
          this.loading = false
          this.$message.success('操作成功')
          this.$emit('reload')
          this.handleClose()
        } catch (e) {
          this.loading = false
        }
      })
    },
    async getBanner() {
      this.loading = true
      try {
        const res = await getBannerById(this.itemId)
        this.loading = false
        res.data.bannerType = String(res.data.bannerType)
        res.data.position = String(res.data.position)
        res.data.status = String(res.data.status)
        this.oldList = [res.data.bannerImg]
        this.formData = res.data

        console.log(
          this.formData,
          'this.formData',
          this.oldList,
          'this.oldList'
        )
      } catch (e) {
        this.loading = false
      }
    },
    handleClose() {
      this.$emit('update:visible', false)
      this.formData = {}
      this.oldList = []
      this.$refs.form.resetFields()
    },
  },
}
</script>

<style></style>
